<svelte:options tag="mh-cc-init-allpages" />
<script>
  import { createEventDispatcher } from 'svelte';

  const dbg = true;
  const dispatch = createEventDispatcher();

  const urlParams = new URLSearchParams(location.search);

  async function getPages(current_pageid) {
    let _opts = {
      method: 'get',
      mode: 'cors'
    };

    let _url;
    if (typeof current_pageid != 'undefined') {
        _url = 'https://rest01.modehaus.de/bpb/page/' + current_pageid;
    }
    else {
        _url = 'https://rest01.modehaus.de/bpb/pages';
    }

    await fetch(_url, _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403 || res.status === 302) ) {
          if (dbg) console.warn('[Modehaus.de - mh-cc-init-allpages]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return {
            action: '[debug] getPages',
            method: '[debug] getMockup',
            pages: []
          };
        }
        else {
          if (dbg) console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        if (dbg) console.log('result received', result);
        if (typeof result.pages != 'undefined') {
            dispatch('pages', {pages: result.pages});
        }
        else {
            dispatch('pages', {pages: result.response});
        }
      })
      .catch(err => {
        dispatch('pages', {pages: []});
      });
  }

  let pagesList;
  if (urlParams.get('id')) {
    pagesList = getPages(urlParams.get('id'));
  }
  else {
    pagesList = getPages();
  }

</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<div class="mh-cc-init-allpages">
</div>


<style type="text/less">
.mh-cc-init-allpages {

}
</style>
