<svelte:options tag="mh-campaign-linkloader" />

<script>

  export let url;
  export let brand = 'Marke';

  const mockup = {
    "action":"scripts/modehaus/campaigns/check4brandpage.php",
    "method":"getBrandpageForProductByBrand",
    "POST":{
      "brand":"Calida"
    },
    "pages":[
      {
        "id":"1",
        "brand":"Calida",
        "imageUrl":"https://rest01.modehaus.de//uploads/tUJv2XSKtbUK/f5ed8d3b_621b_4a00_be94_3c1485c6dc3b/f5ed8d3b_621b_4a00_be94_3c1485c6dc3b.png",
        "title":"YELLOBRATIONS",
        "page_type":"campaign",
        "author":null,
        "brandpage_url":"/campaigns/index/view/id/1/",
        "campaign":"ff12"
      }
    ]
  }

  function getMockup() {
    return mockup;
  }

  async function getBrandsOrCampaignsLink() {

    const _formData = new FormData();
    _formData.append('brand', brand);

    const _opts = {
      method: 'post',
      mode: 'cors',
      body: _formData
    };

    await fetch(url, _opts)
      .then(res => {
        if (!res.ok && (res.status === 404 || res.status === 403) ) {
          console.warn('[Modehaus.de - mh-campaign-linkloader]\nErforderlicher Endpunkt ist nicht erreichbar.\nMockup-Daten werden verwendet!');
          return getMockup();
        }
        else {
          console.log('res json received', res);
          return res.json()
        }
      })
      .then(result => {
        console.log('result received', result);
        data = (result.pages.length > 0) ? result.pages[0] : null;
      })
      .catch(err => {
        console.error(err);
      });
  }

  let data = getBrandsOrCampaignsLink();
</script>

<!-- Bestandteil des Modehaus.de-Netzwerkes -->
<div class="mh-campaign-linkloader">
{#await data}
  
{:then link}
  {#if link}
  <a class="btn" href="{link.brandpage_url}">
    <span>
      {link.title}
    </span>
  </a>
  {/if}
{:catch error}
{/await}  
</div>


<style type="text/less">
.mh-campaign-linkloader {
  .btn {
    display: inline-block;
    width: auto;
    padding: 0.4em 1em;
    background: rgba(255,255,255,0.6);
    border: 1px solid rgba(0,0,0,0.4);
    text-decoration: none;
    
    span {
      display: inline-block;
      transition: transform 200ms ease-in;
    }

    &:hover {

      span {
        transform: scale(1.04);
      }
    }
  }
}
</style>